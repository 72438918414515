/* eslint-disable no-unused-vars */

const state = {
    type: "",
    msg: "" 
}

const actions = {
    showError(commit, data) {
        this.commit('global/ERROR', data)
    },
    showSuccess(commit, data) {
        this.commit('global/SUCCESS', data)
    }
}

const mutations = {
    SUCCESS(state, data) {
        state.type = 'success'
        state.msg = data
        setTimeout(() => {
            state.type = ""
            state.msg = ""
        }, 7000)
    },
    ERROR(state, data) {
        state.type = 'error'
        state.msg = data
        setTimeout(() => {
            state.type = ""
            state.msg = ""
        }, 7000)
    }
}

const getters = {
    getType(state) {
        return state.type 
    },
    getMsg(state) {
        return state.msg
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }