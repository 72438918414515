/* eslint-disable no-unused-vars */

const state = {
    token: localStorage.getItem('token'),
    userId: localStorage.getItem('userId'),
    branch: localStorage.getItem('branch')
}

const getters = {
    isLogin (state) {
        return state.token !== null
    },
    branch(state) {
        return state.branch
    }
}

const actions = {
    async loginUser(commit, data) {
        try {
            this.commit('auth/USER_LOGIN', data)
        } catch (error) {
            console.log(error)
        }
    } ,
    logoutUser(commit, data) {
        this.commit('auth/USER_LOGOUT', data);
    }
}

const mutations = {
    USER_LOGIN(state, data) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('userId', data.auth.id)
        localStorage.setItem('branch', data.auth.branch_id)

        state.token = data.token
        state.userId = data.auth.id
        state.branch = data.auth.branch_id
    },
    USER_LOGOUT(state, data) {
        localStorage.clear()

        state.token = localStorage.getItem('token')
        state.userId = localStorage.getItem('userId')
        state.branch = localStorage.getItem('branch')
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }