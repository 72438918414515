const config = {
  base_url: 'https://admin.funwan.sg/api/',
  headers: {
    'content-type': 'application/json',
    accept: 'application/json',
  },
  auth_headers: {
    'content-type': 'application/json',
    accept: 'application/json',
    authorization: 'Bearer ' + localStorage.getItem('token'),
  },
};

export default config;
