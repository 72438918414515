import { createStore } from 'vuex';
import auth from './Auth';
import global from './Global';

const store = createStore({
    modules: {
      auth,
      global
    },
  })

  export default store