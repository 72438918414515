<template>
  <SideBar>
    <VueSpinnerHourglass
      size="100"
      color="blue"
      :class="'absolute top-50 left-50 hourgalss z-50'"
      v-show="loading"
    />
    <div
      class="fixed bottom-5 right-5 clear-both bg-blue-300 sm:w-2/6 md:1/6 lg:w-60 p-3 opacity-50"
    >
      <p class="px-4 text-left m-0 text-gray-500 text-sm leading-3">
        Display Pending Orders
      </p>
      <p class="text-left text-gray-500 text-sm leading-5">
        <small class="px-4 text-left m-0"
          >show all orders in pending state</small
        >
      </p>
    </div>
    <order-card
      class="mt-3"
      :class="loading ? 'd-orders' : ''"
      :orders="orders"
      v-slot="{ order }"
    >
      <div class="flex justify-between px-6 pt-4 pb-2">
        <span
          @click="cancel(order.id)"
          class="cursor-pointer bg-red-300 inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-600 mr-2 mb-2"
          >Cancel</span
        >
        <span
          @click="ready(order.id)"
          class="cursor-pointer bg-green-300 inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-600 mr-2 mb-2"
          >Ready</span
        >
      </div>
    </order-card>
    <audio
      ref="audio"
      src="/Ding.mp3"
      type="audio/mpeg"
      preload
      id="audio"
    ></audio>
  </SideBar>
</template>
<script>
/* eslint-disable */

import SideBar from "./SideBar.vue";
import OrderCard from "../components/OrderCard.vue";
import { mapActions } from "vuex";
import {
  getPendingOrders,
  readyOrder,
  cancelOrder,
  getSettings,
} from "../api/orders";
import { VueSpinnerHourglass } from "vue3-spinners";
export default {
  components: {
    OrderCard,
    SideBar,
    VueSpinnerHourglass,
  },
  created() {
    window.Echo.channel("order-placing-channel." + this.branch).listen(
      ".Modules\\Order\\app\\Events\\OrderPlacedEvent",
      async (e) => {
        const order = JSON.parse(e.order);
        this.play();
        this.orders.push(order);
      }
    );

    window.Echo.channel("order-cancelled-channel." + this.branch).listen(
      ".Modules\\Order\\app\\Events\\OrderCancelledEvent",
      async (e) => {
        const data = JSON.parse(e.order);
        this.orders = this.orders.filter((order) => order.id !== data.id);
      }
    );

    window.Echo.channel("ready-order-channel." + this.branch).listen(
      ".Modules\\Order\\app\\Events\\OrderReadyEvent",
      async (e) => {
        const data = JSON.parse(e.order);
        this.orders = this.orders.filter((order) => order.id !== data.id);
      }
    );
  },
  mounted() {
    this.getOrders();
    this.getSettingsData();
  },
  data() {
    return {
      orders: [],
      branch: this.$store.getters["auth/branch"],
      loading: false,
      settings: {},
      port: "",
      ip: "",
    };
  },
  methods: {
    ...mapActions("global", ["showError", "showSuccess"]),
    play() {
      this.$refs.audio.play();
    },
    getSettingsData() {
      this.loading = true;
      getSettings()
        .then((response) => {
          if (response.status) {
            this.loading = false;
            this.settings = response.data;
            if (this.settings) {
              (this.port = this.settings.port), (this.ip = this.settings.ip);
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },
    getOrders() {
      this.loading = true;
      getPendingOrders()
        .then((response) => {
          if (response.status) {
            this.orders = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },
    cancel(id) {
      this.loading = true;
      cancelOrder({ id: id })
        .then((response) => {
          if (response.status) {
            this.loading = false;
            this.showSuccess(response.message);
          } else {
            this.loading = false;
            this.showError(response.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },
    async ready(id) {
      this.loading = true;
      try {
        const ready = await readyOrder({ id: id });

        if (ready.status) {
          this.loading = false;
          const print = await this.print(ready.data);

          if (print) {
            this.showSuccess(ready.message);
          } else {
            this.showError("Some error occurred while printing");
          }
        } else {
          this.showError(ready.message);
        }
      } catch (error) {
        console.log(error);
        this.showError(error);
      }
      // .then((response) => {
      //   if (response.status) {
      //     this.loading = false;
      //     const print = this.print(response.data);
      //     if(print) {
      //       this.showSuccess(response.message);
      //     } else {
      //       throw "Some error occurred while printing"
      //     }
      //   } else {
      //     this.loading = false;
      //     this.showError(response.message);
      //   }
      // })
      // .catch((error) => {
      //   this.loading = false;
      //   this.showError(error);
      // });
    },
    formatReceiptForThermalPrinter(data) {
      let receipt = "";
      const orderDetails = data;
      // Reprint notification
      if (orderDetails.reprint > 0) {
        receipt += "\x1b\x45\x01"; // Bold on
        receipt += "\x1b\x61\x02"; // Right align
        receipt += "Reprint\n";
        receipt += "\x1b\x45\x00"; // Bold off
      }

      // Header
      receipt += "\x1b\x61\x01"; // Center align
      receipt += "\x1b\x21\x20"; // Double height
      receipt += "Fun Wan\n";
      receipt += "\x1b\x21\x00"; // Normal text
      receipt += "77 Robinson Road, 01-04\nSingapore 068896\n";
      receipt += "Email: hello@funwan.sg\n";
      receipt += "------------------------------------------------\n";

      // Order Info
      receipt += "\x1b\x61\x01"; // Center align
      receipt += `Order No: ${orderDetails.code}\n`;
      receipt += `${
        orderDetails.type === "dine-in" ? "DINE - IN" : "TAKE AWAY"
      }\n`;
      receipt += `Bill No: ${orderDetails.code}\n`;
      receipt += `${orderDetails.order_date}\n`;

      // Items
      receipt += "Item                           Price  Qty   Total\n";
      receipt += "------------------------------------------------\n";

      orderDetails.orderProducts.forEach((order) => {
        let itemName = order.product_name;
        const itemPrice = order.unit_price.toFixed(2).padStart(5, " ");
        const itemQty = String(order.quantity).padStart(2, " ");
        const itemTotal = (order.unit_price * order.quantity)
          .toFixed(2)
          .padStart(6, " ");

        // Word-wrap for item names
        while (itemName.length > 36) {
          receipt += `${itemName.substring(0, 36)}\n`;
          itemName = itemName.substring(36);
        }
        receipt += `${itemName.padEnd(
          36,
          " "
        )}${itemPrice}  ${itemQty}  ${itemTotal}\n`;

        if (order.addons.length > 0) {
          order.addons.forEach((addon) => {
            let addonName = `+ ${addon.product_name}`;
            const addonPrice = addon.unit_price.toFixed(2).padStart(5, " ");
            const addonQty = String(addon.quantity).padStart(2, " ");
            const addonTotal = (addon.unit_price * addon.quantity)
              .toFixed(2)
              .padStart(6, " ");

            // Word-wrap for addon names
            while (addonName.length > 36) {
              receipt += `${addonName.substring(0, 36)}\n`;
              addonName = addonName.substring(36);
            }
            receipt += `${addonName.padEnd(
              36,
              " "
            )}${addonPrice}  ${addonQty}  ${addonTotal}\n`;
          });
        }
      });

      // Summary
      receipt += "------------------------------------------------\n";
      receipt += `Sub Total:                 $ ${orderDetails.sub_total.toFixed(
        2
      )}\n`;
      receipt += `Discount:                  $ ${orderDetails.discount.toFixed(
        2
      )}\n`;
      receipt += `GST (9%):                  $ ${orderDetails.gst.toFixed(
        2
      )}\n`;
      receipt += `Grand Total:               $ ${orderDetails.total.toFixed(
        2
      )}\n`;
      receipt += "------------------------------------------------\n";

      // Footer
      receipt += "\x1b\x61\x01"; // Center align
      receipt += "Thank you and see you again\n";

      return receipt;
    },
    async print(data) {
      const postData = {
        data: data,
        config: {
          ip: this.ip,
          port: this.port
        }
      }
      if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify(postData)
        );
        return true;
      } else {
        return false;
      }
      // const text = this.formatReceiptForThermalPrinter(data);
      // const url = `https://${this.ip}:${this.port}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000`;
      // const payload =
      //   "\x1B\x40" + // Initialize printer
      //   text +
      //   "\n\n\n" + // Add line feeds to push the paper
      //   "\x1B\x64\x03" + // Feed paper by 5 lines
      //   "\x1D\x56\x42\x00"; // Full cut
      // //  '\x1B\x69' +           // Partial cut
      // //  '\x1B\x64\x03' +       // Feed more paper (ensure paper fully exits the printer)
      // try {
      //   const response = await fetch(url, {
      //     method: "POST",
      //     body: payload,
      //   })

      //   if (response.ok) {
      //     console.log("Print command sent successfully");
      //     return true; // Return success as true if the response is OK
      //   } else {
      //     console.error("Failed to send print command");
      //     return false; // Return success as false if the response is not OK
      //   }
      // } catch (error) {
      //   console.error("Error:", error);
      //   return false; // Return success as false if an error occurs
      // }
    },
  },
};
</script>