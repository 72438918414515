import config from '../config'

const branch = localStorage.getItem('branch')

export async function getPendingOrders() {
    return fetch(config.base_url+branch+"/order/get-pending-orders", {
        method: 'get',
        headers: config.headers
    }).then(response => response.json())
}

export async function getReadyOrders() {
    return fetch(config.base_url+branch+'/order/get-ready-orders', {
        method: 'get',
        headers: config.headers
    }).then(response => response.json())
}

export async function readyOrder(data) {
    return fetch(config.base_url+branch+'/order/ready-order', {
        method: 'post',
        headers: config.headers,
        body: JSON.stringify(data)
    }).then(response => response.json())
}

export async function cancelOrder(data) {
    return fetch(config.base_url+branch+'/order/cancel-order', {
        method: 'post',
        headers: config.headers,
        body: JSON.stringify(data)
    }).then(response => response.json())
}

export async function serveOrder(data) {
    return fetch(config.base_url+branch+'/order/serve-order', {
        method: 'post',
        headers: config.headers,
        body: JSON.stringify(data)
    }).then(response => response.json())
}

export async function getSettings() {
    return fetch(config.base_url+branch+'/settings/get-kds-configuration' , {
        method: 'get',
        headers: config.auth_headers
    }).then(response => response.json())
}

export async function saveSettings(data) {
    return fetch(config.base_url+branch+'/settings/store-kds-configuration' , {
        method: 'post',
        headers: config.auth_headers,
        body: JSON.stringify(data)
    }).then(response => response.json())
}