<template>
    <li class="text-gray-700 mx-3 border-b-2 list-none border-yellow-200 bg-yellow-100" v-for="(addon, i) in addons" :key="i">
        <p class="px-2 text-left text-md">
           <small class="text-sm">-  <span>{{ addon.product_name }}</span></small>
        </p>
    </li>
</template>
<script>
export default {
    props: ['addons']
}
</script>
