<template>
  <VueSpinnerHourglass
    size="100"
    color="blue"
    :class="'absolute top-50 left-50 hourgalss z-50'"
    v-show="loading"
  />
  <div
    class="flex flex-col mx-auto bg-gray-100 rounded-lg h-screen"
    :class="loading ? 'd-orders' : ''"
  >
    <div class="flex xs:flex-col sm:flex-row" id="bg-img">
      <div class="xs:w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2">
        <div class="relative text-left">
          <form class="absolute intrepid translate-middle form">
            <h2 class="text-gray login-admin-text">Login (Admin)</h2>
            <div class="mb-2">
              <label for="email" class="form-label text-gray">Email&nbsp;&nbsp;</label>
              <input
                type="email"
                v-model="email"
                class="form-control form-input p-1"
                name="email"
                placeholder="xyz@gmail.com"
                required
              />
            </div>
            <div class="mb-4 bottom-space">
              <label for="password" class="form-label text-gray">Password</label>
              <input
                type="password"
                v-model="password"
                class="form-control form-input p-1"
                name="password"
                placeholder="Password"
                required
              />
            </div>
            <button type="button" class="form-control form-input p-1 sign-in" @click="submit">
              Sign in
            </button>
          </form>

          <img :src="require(`../assets/images/logo.png`)" class="logo" alt="Logo Image" />
          <img :src="require(`../assets/images/lines.png`)" class="lines" alt="Lines Image" />
          <img
            :src="require(`../assets/images/waves.png`)"
            class="waves-bottom"
            alt="Waves Image"
          />
        </div>
      </div>
      <div class="xs:w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2">
        <div class="rectangular-div">
          <h1 class="sensa kds-text text-orange">KITCHEN DISPLAY SCREEN</h1>
          <h1 class="brittany dashboard-text text-gray">Dashboard</h1>
          <ul class="rectangular-div-isting text-gray intrepid list-none">
            <li
              class="relative before:inline-block before:content-['\2022'] before:mr-2 before:align-middle"
            >
              It is a long established fact that a reader will be.
            </li>
            <li
              class="relative before:inline-block before:content-['\2022'] before:mr-2 before:align-middle"
            >
              There are many variations of passages of Lorem Ipsum available.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { loginUser } from '../api/auth';
import { VueSpinnerHourglass } from 'vue3-spinners';
import './../assets/login.css';

export default {
  components: {
    VueSpinnerHourglass,
  },
  data() {
    return {
      email: '',
      password: '',
      signedIn: 0,
      loading: false,
    };
  },
  methods: {
    ...mapActions('auth', ['loginUser']),
    ...mapActions('global', ['showError', 'showSuccess']),

    submit() {
      this.loading = true;
      loginUser({ email: this.email, password: this.password, type: 'kds' })
        .then((response) => {
          if (response.status) {
            this.loginUser(response.data);
            setTimeout(() => {
              this.loading = false;
              this.showSuccess(response.message);
              window.location.href = '/';
            }, 2000);
          } else {
            this.loading = false;
            this.showError(response.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },
  },
};
</script>
