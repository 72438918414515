import config from '../config';

export async function loginUser (data) {
    return fetch(config.base_url+'auth/login', {
        method: 'post',
        headers: {
            'content-type': 'appliation/json',
            'accept': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => response.json())
}
