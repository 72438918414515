<template>

  <div class="flex justify-center fixed top-10 right-10 z-50" v-show="getType == 'error'">
    <div
      class="p-4 mb-4 text-sm border-2 border-red-800 text-red-800 rounded-lg bg-red-50"
      role="alert"
    >
      <span class="font-medium">Error alert!</span> {{ getMessage}}
    </div>
  </div>

  <div class="flex justify-center fixed top-10 right-10 z-50"  v-show="getType == 'success'">
    <div
      class="p-4 mb-4 text-sm border-2 border-green-800 text-green-800 rounded-lg bg-green-50"
      role="alert"
    >
      <span class="font-medium">Success alert!</span> {{ getMessage }}
    </div>
  </div>

</template>
<script>
export default {
  data() {
    return {
      msg: "",
      type: ""
    }
  },
  computed: {
    getMessage() {
      return this.$store.getters['global/getMsg'];
    },
    getType() {
      return this.$store.getters['global/getType']
    }
  },
  mounted() {
    console.log(this.getType)

  }
};
</script>