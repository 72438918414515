<template>
  <SideBar>
    <VueSpinnerHourglass
      size="100"
      color="blue"
      :class="'absolute top-50 left-50 hourgalss z-50'"
      v-show="loading"
    />
    <div
      class="fixed bottom-5 right-5 clear-both bg-blue-300 sm:w-2/6 md:1/6 lg:w-60 p-3 opacity-50"
    >
      <p class="px-4 text-left m-0 text-gray-500 text-sm leading-3">
        Display Ready Orders
      </p>
      <p class="text-left text-gray-500 text-sm leading-5">
        <small class="px-4 text-left m-0">show all orders in ready state</small>
      </p>
    </div>
    <order-card
      :orders="orders"
      :class="loading ? 'd-orders' : ''"
      v-slot="{ order }"
    >
      <div class="flex justify-between px-6 pt-4 pb-2">
        <span
          @click="cancel(order.id)"
          class="cursor-pointer bg-red-300 inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-600 mr-2 mb-2"
          >Cancel</span
        >
        <span
          @click="serve(order.id)"
          class="cursor-pointer bg-green-300 inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-600 mr-2 mb-2"
          >Serve</span
        >
      </div>
    </order-card>
  </SideBar>
  <audio ref="audio" src="/Ding.mp3" preload id="audio"></audio>
</template>
<script>
import SideBar from "./SideBar.vue";
import OrderCard from "../components/OrderCard.vue";
import { mapActions } from "vuex";
import { VueSpinnerHourglass } from "vue3-spinners";
import { getReadyOrders, cancelOrder, serveOrder } from "../api/orders";

export default {
  components: {
    OrderCard,
    SideBar,
    VueSpinnerHourglass,
  },
  mounted() {
    this.getReadyOrders();
  },
  created() {
    window.Echo.channel("order-cancelled-channel." + this.branch).listen(
      ".Modules\\Order\\app\\Events\\OrderCancelledEvent",
      async (e) => {
        const data = JSON.parse(e.order);
        this.orders = this.orders.filter((order) => order.id !== data.id);
      }
    );

    window.Echo.channel("ready-order-channel." + this.branch).listen(
      ".Modules\\Order\\app\\Events\\OrderReadyEvent",
      async (e) => {
        const data = JSON.parse(e.order);
        this.play();
        this.orders.push(data);
      }
    );

    window.Echo.channel("order-served-channel." + this.branch).listen(
      ".Modules\\Order\\app\\Events\\OrderServedEvent",
      async (e) => {
        const data = JSON.parse(e.order);
        this.orders = this.orders.filter((order) => order.id !== data.id);
      }
    );
  },
  data() {
    return {
      orders: [],
      branch: this.$store.getters["auth/branch"],
      loading: false,
    };
  },
  methods: {
    ...mapActions("global", ["showError", "showSuccess"]),
    play() {
      this.$refs.audio.play();
    },
    getReadyOrders() {
      this.loading = true;
      getReadyOrders()
        .then((response) => {
          if (response.status) {
            this.loading = false;
            this.orders = response.data;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },
    cancel(id) {
      this.loading = true;
      cancelOrder({ id: id })
        .then((response) => {
          if (response.status) {
            this.laoding = false;
            this.showSuccess(response.message);
          } else {
            this.loading = false;
            this.showError(response.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },
    serve(id) {
      this.loading = true;
      serveOrder({ id: id })
        .then((response) => {
          if (response.status) {
            this.loading = false;
            this.showSuccess(response.message);
          } else {
            this.loading = false;
            this.showError(response.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },
  },
};
</script>