import { createApp } from 'vue'
import App from './App.vue'
import './assets/index.css';
import axios from 'axios';
import Echo from 'laravel-echo';
import DisplayOrders from './pages/DisplayOrders.vue'
import ReadyOrders from './pages/ReadyOrders.vue'
import LoginPage from './pages/LoginPage.vue'
import OrdersTracking from './pages/OrdersTracking.vue'
import { createRouter, createWebHashHistory } from "vue-router"
import store from './store';
import SettingsComponent from './components/SettingsComponent.vue';

const routes = [
    {
        path: '/login',
        component: LoginPage
    },
    {
        path: '/orders-status',
        component: OrdersTracking
    },
    {
        path: '/',
        name: 'Main',
        redirect: {
            name: 'Home'
        },
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/',
                component: DisplayOrders,
                name: "Home"
            },
            {
                path: '/ready-orders',
                component: ReadyOrders
            },
            {
                path: '/settings',
                component: SettingsComponent
            }
        ]
    }
];



const axiosInstance = axios.create({
    withCredentials: true,
})

const router = createRouter({
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters['auth/isLogin']) {
            next({
                path: '/login'
            })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.requiresVisit)) {
        if (store.getters['auth/isLogin']) {
            next({
                path: '/'
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

const app = createApp(App)

window.pusher = require('pusher-js');
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '095f520b-dfa8-42f2-8388-ea039f7a0e9b',
    wsHost: 'admin.funwan.sg',
    wssPort: 6001,
    cluster: 'ap1',
    encrypted: true, // Use true for HTTPS,
    useTLS: false,
    disableStats: true,
    enabledTransports: ['ws', 'wss'], // Specify available transports
})

app.config.globalProperties.$axios = { ...axiosInstance }
app.use(router)
app.use(store)
app.mount('#app')
