<template>
  <div class="relative">
    <img src="../assets/images/lines.png" alt="Image" style="width: 100vw; height: 100vh" />
    <div class="absolute inset-0">
      <div class="absolute left-1/2 h-full border-l-8 border-black"></div>

      <div class="grid grid-cols-2 pt-4">
        <div class="flex flex-col items-center">
          <h2 class="text-lg font-bold bg-blue-400 text-black p-6 rounded-lg">PREPARATION NOW</h2>
          <div class="mt-4 font-bold" v-for="(order, index) in ongoingOrders" :key="index">
            {{ order.code }}
          </div>
        </div>

        <div class="flex flex-col items-center">
          <h2 class="text-lg font-bold bg-orange-500 text-white p-6 rounded-lg">
            READY FOR COLLECTION
          </h2>
          <div class="mt-4 font-bold" v-for="(order, index) in readyOrders" :key="index">
            {{ order.code }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getPendingOrders, getReadyOrders } from '../api/orders';

export default {
  data() {
    return {
      ongoingOrders: [],
      readyOrders: [],
      branch: this.$store.getters['auth/branch'],
      loading: false,
      timers: {},
    };
  },

  computed: {
    // filteredReadyOrders() {
    //   const currentTime = this.getCurrentDateTime();
    //   return this.readyOrders.filter((order) => {
    //     if (order.ready_at || order.serve_at) {
    //       const secondsDifference = this.findSecondsDifference(currentTime, order.ready_at);
    //       console.log('condition', secondsDifference < 120);
    //       return secondsDifference < 120;
    //     }
    //     return true;
    //   });
    // },
  },

  created() {
    window.Echo.channel('order-placing-channel.' + this.branch).listen(
      '.Modules\\Order\\app\\Events\\OrderPlacedEvent',
      async (e) => {
        const order = JSON.parse(e.order);
        this.ongoingOrders.push(order);
      }
    );

    window.Echo.channel('order-cancelled-channel.' + this.branch).listen(
      '.Modules\\Order\\app\\Events\\OrderCancelledEvent',
      async (e) => {
        const data = JSON.parse(e.order);
        this.ongoingOrders = this.ongoingOrders.filter((order) => order.id !== data.id);
        this.readyOrders = this.readyOrders.filter((order) => order.id !== data.id);
      }
    );

    window.Echo.channel('ready-order-channel.' + this.branch).listen(
      '.Modules\\Order\\app\\Events\\OrderReadyEvent',
      async (e) => {
        const data = JSON.parse(e.order);
        this.readyOrders.push(data);
        this.ongoingOrders = this.ongoingOrders.filter((order) => order.id !== data.id);

        // setTimeout(() => {
        //   const index = this.readyOrders.indexOf(data);
        //   if (index !== -1) {
        //     this.readyOrders.splice(index, 1);
        //   }
        // }, 120000);

        const codeArray = Array.from(data.code);
        const synth = window.speechSynthesis;
        const codeMessage = `Attention: Please collect order numbe ${codeArray.join(', ')}`;

        const utterance = new SpeechSynthesisUtterance(codeMessage);
        utterance.rate = 0.6;
        utterance.pause = 500;
        try {
          synth.speak(utterance);
        } catch (error) {
          console.log(error);
        }
      }
    );

    window.Echo.channel('send-reminder-channel.' + this.branch).listen(
      '.Modules\\Order\\app\\Events\\SendReadyOrderReminderEvent',
      async (e) => {
        const data = JSON.parse(e.order);

        const codeArray = Array.from(data.code);
        const synth = window.speechSynthesis;
        const codeMessage = `Reminder: Please collect order number ${codeArray.join(', ')}`;

        const utterance = new SpeechSynthesisUtterance(codeMessage);
        utterance.rate = 0.6;
        utterance.pause = 500;
        try {
          synth.speak(utterance);
        } catch (error) {
          console.log(error);
        }
      }
    );

    window.Echo.channel('order-served-channel.' + this.branch).listen(
      '.Modules\\Order\\app\\Events\\OrderServedEvent',
      async (e) => {
        const data = JSON.parse(e.order);
        this.handleOrderServed(data);
      }
    );
  },

  mounted() {
    this.getOrders();
    this.getReadyOrders();
  },

  beforeUnmount() {
    Object.values(this.timers).forEach((timer) => {
      clearTimeout(timer);
    });
  },

  methods: {
    ...mapActions('global', ['showError', 'showSuccess']),
    getOrders() {
      this.loading = true;
      getPendingOrders()
        .then((response) => {
          if (response.status) {
            this.ongoingOrders = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },

    getReadyOrders() {
      this.loading = true;
      getReadyOrders()
        .then((response) => {
          if (response.status) {
            this.readyOrders = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },

    handleOrderServed(data) {
      if (this.timers[data.id]) {
        clearTimeout(this.timers[data.id]);
      }

      this.timers[data.id] = setTimeout(() => {
        const index = this.readyOrders.findIndex((order) => order.id === data.id);
        if (index !== -1) {
          this.readyOrders.splice(index, 1);
          delete this.timers[data.id];
        }
      }, 120000);
    },

    getCurrentDateTime() {
      const singaporeOffset = 8 * 60 * 60 * 1000;
      const currentDate = new Date(Date.now() + singaporeOffset);
      const formattedDateTime = currentDate.toISOString().replace('T', ' ').substring(0, 19);
      return formattedDateTime;
    },

    convertToSingaporeTime(utcTimeString) {
      const utcDate = new Date(utcTimeString);
      const singaporeOffset = 8 * 60;
      const singaporeTime = new Date(utcDate.getTime() + singaporeOffset * 60 * 1000);
      const formattedTime = singaporeTime.toISOString().replace('T', ' ').substring(0, 19);

      return formattedTime;
    },

    findSecondsDifference(currentTime, orderReadyAt) {
      const currentTimeObj = new Date(currentTime);
      const orderReadyAtObj = new Date(orderReadyAt);
      const differenceMs = currentTimeObj - orderReadyAtObj;
      const seconds = Math.abs(differenceMs) / 1000;

      return seconds;
    },
  },
};
</script>
